<template>
    <div id="body-content">			

        <!-- Section: Mini Hero -->
        <div class="section-mini-hero" style="background-image: url('img/hero3.jpg');">
            <div class="map-overlay">
                <div class="container">
                    <h1 class="title1 mini-hero-title">{{ $t("contact.contact")}}</h1>
                </div>
            </div>
            <!--<div class="container">
                <h2 class="title2 mini-hero-subtitle">presenting the project:</h2>
                <h1 class="title1 mini-hero-title">ENTERPRISE BUILDING: BIRDNEST</h1>
            </div>-->
        </div>
        <!-- /Section: Mini Hero -->	

        <!-- SECTION: contact infos -->
        <div class="section-contact-infos">
            <div class="container">
                <div class="row">
                    <!-- col -->
                    <div class="col-sm-6 contact-infos-title-wrapper">
                        <div class="contact-infos-col">
                            <h2 class="title1">{{ $t("contact.contactDetail")}} : </h2>
                        </div>					
                    </div>
                    <!-- /col -->
                    <!-- col -->
                    <div class="col-sm-3">
                        <div class="contact-infos-col">
                            <h3 class="title4 head-office">{{ $t("contact.headOffice")}}</h3>
                            <p>{{ $t("contact.addressOffice")}}</p>
                            <p><a href="tel:0819997722" class="router-home">081 - 999 - 7722</a>, <a href="tel:0834249090" class="router-home">083 - 424 - 9090</a></p>
                            <p><a href="mailto:info@assetup.co.th" class="router-home">info@assetup.co.th</a></p>
                        </div>					
                    </div>
                    <!-- /col -->
                    <!-- col -->
                    <div class="col-sm-3">
                        <div class="contact-infos-col">
                            <h3 class="title4 head-office">{{ $t("contact.socialMedia")}}</h3>
                            <ul class="clean-list">
                                <li><a href="https://www.facebook.com/TheAssetUp" class="router-home">Facebook</a></li>
                            </ul>	
                        </div>									
                    </div>
                    <!-- /col -->
                </div>
            </div>
        </div>
        <!-- /SECTION: contact infos -->

        <!-- contact form -->
        <div class="container section-contact-form">
            <div class="row">		
                <div class="col-sm-4">
                    <h2 class="title2 title-border title-section">{{ $t("contact.sendMessage")}}</h2>
                </div>	
                <div class="col-sm-8">
                    <form id="contactForm" method="post" class="form" role="form">        
                        <div class="form-group">
                            <label for="name" class="form-label">{{ $t("contact.name")}}</label>
                            <input class="form-control-1 required" id="name" name="name" type="text" required/>
                        </div>     
                        <div class="row">
                            <div class="col-md-6">          
                                <div class="form-group">
                                    <label for="email" class="form-label">{{ $t("contact.email")}}</label>
                                    <input class="form-control-1 required" id="email" name="email" type="email" required />
                                </div>    
                            </div>
                            <div class="col-md-6">         
                                <div class="form-group">
                                    <label for="subject" class="form-label">{{ $t("contact.subject")}}</label>
                                    <input class="form-control-1 required" id="subject" type="text" name="subject" required>
                                </div>    
                            </div>
                        </div>  						                      
                        <div class="form-group">
                            <label for="message" class="form-label">{{ $t("contact.message")}}</label> 
                            <textarea class="form-control-1 required" id="message" name="message" required></textarea>
                        </div>    
                        <div class="form-group form-send">
                            <button type="submit" value="" class="btn btn-form btn2"><span class="fa fa-paper-plane"> &nbsp;</span>{{ $t("contact.send")}}</button>
                        </div>                     
                    </form>	
                </div>
            </div>
        </div>
        <!-- / contact form -->	

        <div class="map">			
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3026.6617384330816!2d-73.9992296355825!3d40.65938630000002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25ae9ec6e2cb3%3A0x39c56782050e447a!2s27th+St%2C+Brooklyn%2C+NY+11232%2C+USA!5e0!3m2!1spt-BR!2sbr!4v1431959746282" height="280" style="border:0"></iframe>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>